import React from "react";
import axios from "axios";
import leftcupImage  from '../../assets/left_cup.gif';
import rightcupImage from '../../assets/right_cup.gif';
import BookLinkImage from '../../assets/Book-a-link.gif';
import './RankPredictor.css';
import 'animate.css';
class RankPredictor extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            message:<div></div>,
        }
        this.rankPredictor=this.rankPredictor.bind(this);
    }

    rankPredictor(e)
    {
        e.preventDefault();
     
        
        let score= document.getElementById("rank_predictor_score").value;
        let course_level= document.getElementById("rank_predictor_course_level").value;
        let phone_number =document.getElementById("rank_predictor_phone_number").value;
        let formData = new FormData(document.getElementById('rank_predictor_form'));
        
        formData.append("score",score);
        formData.append("course_level",course_level);
        formData.append("phone_number",phone_number);
        
        function digits_count(n) {
            var count = 0;
            if (n >= 1) ++count;
          
            while (n / 10 >= 1) {
              n /= 10;
              ++count;
            }
          
            return count;
          }


         if(phone_number==="" || phone_number===null)
        {
            this.setState({message:<div class="alert alert-danger alert-dismissible fade show text-center padding-inside border-radius" role="alert">
            <h2>Please Enter All the fields</h2>
            
          </div>})
        }
        if(digits_count(phone_number)<10 || digits_count(phone_number)>10)
        {
            this.setState({message:<div class="alert alert-danger alert-dismissible fade show text-center padding-inside border-radius" role="alert">
            <h2>Please Enter Valid Phone Number To Get OTP</h2>
            
          </div>})
        }
   
         else if(parseInt(course_level)!=0){
            
        axios.post("https://api.prod.goocampus.in/api/user/get_home_rank_predictor",formData)
       .then((response) => {
        if(response.data.code==204)
        {
            this.setState({
                message:<div class="alert alert-danger alert-dismissible fade show  text-center padding-inside border-radius" role="alert">
           <h2>{response.data.message}</h2> 
          </div>})   
        }else{
          this.setState({message:<div class="alert alert-success alert-dismissible fade show text-center padding-inside border-radius" role="alert">
          <h2>{response.data.message}</h2>
        </div>})
        }  
        });
    }
    else if(parseInt(course_level)===0){
        this.setState({message:<div class="alert alert-danger alert-dismissible fade show text-center padding-inside border-radius" role="alert">
        <h2>Please Select the Course Level</h2>
        
      </div>})
    }
    
    }
    render()
    {
        return(
            <div className="container p-0 rank_main_div">
        
                <p className="text-center rank_predictor_title"><span><img className="animate__animated animate__slideInLeft" src={leftcupImage} alt="left_cup"/></span>NEET Rank Predictor<span><img className="animate__animated animate__slideInRight" src={rightcupImage} alt="right cup"/></span></p>
                <form id="rank_predictor_form" >
                    <div className="row">
                       
                        <div className="col-md-3 text-right">
                            <select className="form-control border-radius mt-3" id="rank_predictor_course_level" name="rank_predictor_course_level">
                                <option value={0}>Select your course level</option>
                                <option value={1}>UG</option>
                                <option value={2}>PG</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <input type="number" className="form-control border-radius py-3 mt-3" id="rank_predictor_score"  placeholder="Enter Your Score" />
                        </div>
                        <div className="col-md-5">
                            <input type="number" className="form-control border-radius py-3 mt-3" id="rank_predictor_phone_number"  placeholder="Enter Your Phone Number To Get an OTP" />
                        </div>
                        <div className="col-md-1">
                            <button type="submit" onClick={this.rankPredictor} className="btn-lg border-radius abroad_btn no_shadow mt-3">Predict</button>
                        </div>
                       
                        
                    </div>
                    </form>
            <div className="row">
                <div className="col-md-12 mt-3">
                    {this.state.message}
                </div>
                <div className="col-md-12 mt-3">
                    <a href="https://bit.ly/NEET_Consult" target=""><img className="animate_image" src={BookLinkImage} width="100%" alt="link"/></a>    
                </div>
                <div className="col-md-12 text-center">
                    <button class="btn btn-lg no_shadow mr-2 py-1 px-2 border-radius animate_button" style={{fontSize:"1.94rem",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",background: "#084578",color: "white", border: "2px solid rgb(23, 42, 58)"}}><b><a style={{color:"white"}} href="tel:7483524515"> OR Call 7483524515 </a></b></button>
                </div>  
                
            </div>
            </div>
            
            );
    }
}


export default RankPredictor;