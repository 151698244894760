import React from "react";
import "./Result.css";
import { connect } from "react-redux";
import { getNEETResult } from "../../actions/Home";
import $ from "jquery";

class Result extends React.Component {
    _handleGetResults = e => {
        e.preventDefault();
        var roll_number = $("#roll_number").val();
        this.props.onGetResult(roll_number);
    };

    render() {
        const { result } = this.props;

        return (
            <div className="row mt-4 pt-4">
                <div className="col">
                    <div className="container checkout_container text-center">
                        <h2 className="text-orange"> MBBS/MS/MD RESULTS - 2020</h2>
                        <h5 className="text-success">
                            {" "}
                            <i className="fa fa-thumbs-up"></i> All the best.{" "}
                        </h5>

                        <div className="row">
                            <div className="col"></div>
                            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 p-4 card mt-3">
                                <form
                                    onSubmit={this._handleGetResults}
                                    autoComplete="off">
                                    <div className="form-group text-left">
                                        <label className="small_text">
                                            {" "}
                                            Roll number * :{" "}
                                        </label>
                                        <input
                                            type="text"
                                            id="roll_number"
                                            className="form-control"
                                            placeholder="Enter roll number"
                                            required
                                        />
                                    </div>

                                    <div className="form-group mt-4">
                                        <button className="abroad_btn w-50">
                                            <i className="fa fa-check text-light"></i>{" "}
                                            CHECK NOW
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col"></div>
                        </div>

                        {/* Results display container */}
                        {result && result.length > 0
                            ? result.map((res, index) => (
                                  <div className="mt-5" key={index}>
                                      <h5 className="text-blue">
                                          Roll number : {res.roll_number}
                                      </h5>
                                      <h2 className="text-orange">
                                          Rank : {res.rank}
                                      </h2>
                                      <h2 className="text-orange">
                                          Score : {res.score}
                                      </h2>
                                  </div>
                              ))
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        result: state.home.result
    };
};

const mapActionsToProps = {
    onGetResult: getNEETResult
};

export default connect(mapStatesToProps, mapActionsToProps)(Result);
